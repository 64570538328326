import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import BackgroundSection from "../components/Globals/BackgroundSection"
import Info from "../components/Home/Info"
import Casestudies from "../components/Home/Casestudies"
import Cases from "../components/Home/Cases"
import Contact from "../components/Home/Contact"

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="Home"/>
    <a id="home">
    <BackgroundSection
      img={data.img.childImageSharp.fluid}
      title=""
      styleClass="default-background"/>
    </a>
    <a id="our-story">
      <Info/>
    </a>
    {/*<Casestudies items={data.casestudies}/>*/}
    <a id="case-studies">
      <Cases/>
    </a>
    <a id="contact-us">
    <Contact/>
    </a>
  </Layout>
)

export const query = graphql`
{
  img: file(relativePath: {eq: "default-background.jpg"}) {
    childImageSharp {
      fluid(quality: 100, maxWidth: 2000) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
  casestudies: allContentfulCasestudy {
    edges {
      node {
        id
        title
        text
        image
        text
        logo {
          fixed {
            ...GatsbyContentfulFixed_withWebp
          }
        }
      }
    }
  }
}
`

export default IndexPage
