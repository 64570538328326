import React from "react"
import Img from 'gatsby-image'


function Case({ node }) {
  console.log(node)
  return (
    <div className="col-10 col-sm-8 col-md-6 col-lg-4 mx-auto my-3">
      <div className="card p-4" style={{minHeight:"100%"}}>
        <Img fluid={node.logo.fluid} className="card-img-top p2"/>
        <div className="card-body text-center p-2">
          {/*<h4 className="p-4">{node.title}</h4>*/}
          <p style={{paddingTop: "0.5rem"}} className="text-muted">{node.text}</p>
        </div>
      </div>
    </div>
  )
}

export default Case
