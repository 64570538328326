import React from "react"
import Title from "../Globals/Title"

function Contact(props) {
  return (
    <section className="contact py-5">
      <Title title={"Contact us"}/>

      <div className="row">

        <div className="col-10 col-sm-8 mx-auto text-center">
          <p className="lead text-muted mb-5">
            Please contact us at <bold>enquiries@dodion.co.uk</bold> if you have a requirement that you would like to discuss or if you are
            interested in joining our team.
          </p>
        </div>

      </div>
    </section>
  )
}

export default Contact
