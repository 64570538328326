import React from "react"
import Case from "./Case"
import Title from "../Globals/Title"
import { StaticQuery, graphql } from "gatsby"

const getCases = graphql`
    {
  cases: allContentfulCasestudy {
    edges {
      node {
        id
        title
        text
        image
        logo {
          fluid(maxHeight:200) {
            ...GatsbyContentfulFluid_tracedSVG
          }
        }
      }
    }
  }
}
    `

function Cases(props) {
  return (
    <StaticQuery query={getCases} render={data => {
      return (
        <section className="py-5">
          <div className="container">
            <Title title="Cases studies"/>
            <div className="row">
              {data.cases.edges.map(({ node }) => {
                console.log(node.id)
                return <Case key={node.id} node={node}/>
              })}
            </div>
          </div>
        </section>
      )
    }}/>
  )
}

export default Cases
