import React, { Component } from "react"
import Title from "../Globals/Title"
import Img from "gatsby-image"

class Casestudies extends Component {
  constructor(props) {
    super(props)
    this.state = {
      items: props.items.edges,
    }
    console.log(props)
    console.log(this.state.items)
  }


  render() {
    if (this.state.items.length > 0) {
      return (
        <section className="casestudies py-5">
          <div className="container">
            <Title title="Casestudies"/>
            <div className="row">
              {this.state.items.map(({ node }) => {
                return (
                  <div key={node.id} className="col-11 col-md-6 my-3 d-flex mx-auto casestudy">

                    <Img fixed={node.logo.fixed}/>

                    <div className="flex-grow-1 px-3">
                      <h2>{node.title}</h2>
                      <p>{node.description}</p>
                      <p>{node.text}</p>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </section>
      )
    } else {
      return (
        <section className="casestudies py-5">
          <div className="container">
            <Title title="Casestudies"/>
            <div className="row">
              <div className="col-10 col-sm-6 mx-auto text-center text-capitalize">
                <h1>There are no casestudies...</h1>
              </div>
            </div>
          </div>
        </section>
      )
    }
  }
}

export default Casestudies
